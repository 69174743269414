<template>
    <div id="app">
        <transition>
            <router-view />
        </transition>
        <!-- <global-footer></global-footer>-->
    </div>
</template>

<script>
//import Footer from "./components/Globals/Footer.vue";

export default {
    components: {
        //"global-footer": Footer
    }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

.v-enter-active, .v-leave-active {
    transition: opacity .5s;
}

.v-enter, .v-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
