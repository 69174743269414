<template>
    <div class="w-full h-screen flex justify-center items-center absolute bg-white overflow-hidden z-10">
        <div class="flex flex-col justify-evenly w-full">
            <!-- SELECCIONA TU CIUDAD -->
            <div class="text-grey w-auto flex flex-col justify-center">
                <!-- LOGO POWER flex-grow-0 md:flex-grow-0 cpt-12 md:pt-14 -->
            <div class="flex justify-center">
                <img class="logoDeliverySm md:hidden -mt-3 md:pt-10" src="../assets/images/logo-power-chicken.svg" alt="Logo PowerChicken" />
                <img class="hidden md:block md:logoDeliveryMd -mt-3 md:pt-10" src="../assets/images/logo_delivery_desktop.png" alt="Logo PowerChicken" />
                <!--<img class="logoDeliverySm md:logoDeliveryMd" src="../assets/images/logo_delivery.svg" alt="Logo PowerChicken" />-->
            </div>
                <div class="px-10">
                    <p class="text-left font-light text-3xl py-5 md:text-center ">¿Qué tipo de pedido deseas realizar?</p>
                    <!-- <p class="text-center text-xl font-light flex flex-col justify-center md:flex-row gap-5"> -->
                    <p class="text-center text-xl font-light btnContent">
                        <a class="btnSucursal" :href="data.urlNormal">Pedido normal</a>
                        <a class="btnSucursal" :href="data.urlPreventa">Preventa día de las madres</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'animate.css';

export default {
    name: "modaloptions",
    props: {
        data: Object
    },
    components: {
        
    },
    data() {
        return {
            onCity: false,
        }
    },
    computed:{
        inCity () {
            if(this.onCity){
                this.animateCities();
                return true;
            }else{
                this.animateCities();
                return false;
            }
        }
    },
    created(){
    },
    mounted(){
        this.animateCities();
        //this.showBranchOffice();
    },
    methods:{
        animateCities(){
            const cities = document.querySelectorAll('.cities a');
            const cityMd = document.querySelectorAll('#cities a');
            
            cities.forEach((link, index)=>{// animate on desktop
                setTimeout(()=>{
                    link.classList.toggle('animate__animated');
                    link.classList.toggle('animate__fadeInDown');
                    //link.classList.toggle('animate__delay-2s');
                },300*index);
            });

            cityMd.forEach((link, index)=>{// animate on movil
                setTimeout(()=>{
                    link.classList.toggle('animate__animated');
                    link.classList.toggle('animate__fadeInDown');
                    //link.classList.toggle('animate__delay-2s');
                },300*index);
            });
            
        },
        goBack(){
            this.onCity = !this.onCity;
            this.animateCities();
        },
        showBranchOffice(){
           /*  const branchOfficeList = document.querySelector('#sps-branchOfficeList');
            const spsbranchOfficeList = document.querySelectorAll('#sps-branchOfficeList a');
            const  branchOffice = document.querySelector('.cityMain');
            const  branchOfficeEmpty = document.querySelector('.empty');
            const chevron = document.querySelector('.chevron-right');

            branchOffice.addEventListener('click', function (){
                chevron.classList.remove('hidden');
                branchOfficeList.classList.remove('hidden');
                branchOfficeList.classList.add('block');
        
                spsbranchOfficeList.forEach((link, index)=>{// animate branchOffice on desktop
                    setTimeout(()=>{
                        link.classList.toggle('animate__animated');
                        link.classList.toggle('animate__fadeInDown');
                        //link.classList.toggle('animate__delay-2s');
                    },300*index);
                });
            });

            branchOfficeEmpty.addEventListener('click', function (){
                branchOfficeList.classList.remove('block');
                branchOfficeList.classList.add('hidden');
                chevron.classList.remove('hidden');
                chevron.classList.add('hidden');
            }); */
        },
    }
};
</script>

<style lang="scss" scoped>
$mainFont : 'Montserrat', sans-serif;

.btnContent{
    display: grid;
    flex-direction: column;
    grid-gap: 1.5rem;
}

@media screen and (min-width: 768px) {
    .btnContent{
        
        flex-direction: row;
        justify-items: center;
        grid-template-columns: repeat(2, 1fr);
        .btnSucursal{
            float: left;
        }
    }
}

body, p{
    font-family: $mainFont;
}

.city {
    color: rgb(63, 62, 62);
    font-family: $mainFont;
}

/*
 Transitions
*/
.v-enter-active, .v-leave-active {
    transition: opacity .5s;
}

.v-enter, .v-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.cities a:active,
.cities a:focus{
    border-color:transparent;
}

.cities:hover a:hover{
    color: #DA2D2A;
    opacity: 1;
}

.cities:hover a.active{
    color: #DA2D2A;
    opacity: 1;
}

.cities:hover a{
  color: rgba(0,0,0,.2);
}

.chevron-right{
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid #444950;
    transform: rotate(45deg);
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    border-radius: 2px;
    margin-right: -1.3rem;
}

.btnSucursal{
    border: 1px solid #abbbc8;
    padding: .8rem 1.5rem;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btnSucursal:hover{
    border-color: #0a99fe;
  background-color: #eff6fb;
}

</style>
