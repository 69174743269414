<template>
    <div class="h-screen flex justify-center md:justify-start md:px-32">
        <div class="flex flex-col justify-evenly w-full relative">
        <modalOptions :data="dataToModal" v-show="isShowModal" />
            <!-- LOGO POWER flex-grow-0 md:flex-grow-0 cpt-12 md:pt-14 -->
            <div class="flex justify-center md:justify-start">
                <img class="logoDeliverySm md:hidden -mt-3 md:pt-10" src="../assets/images/logo-power-chicken.svg" alt="Logo PowerChicken" />
                <img class="hidden md:block md:logoDeliveryMd -mt-3 md:pt-10" src="../assets/images/logo_delivery_desktop.png" alt="Logo PowerChicken" />
                <!--<img class="logoDeliverySm md:logoDeliveryMd" src="../assets/images/logo_delivery.svg" alt="Logo PowerChicken" />-->
            </div>
            <!-- SELECCIONA TU CIUDAD -->
            <div class="-pt-16 md:hidden text-grey uppercase flex-grow-0">
                <transition>
                    <p v-if="!inCity" class="text-center text-sm cursor-pointer font-light">
                        <span>Selecciona tu ciudad</span> <span class="text-xl">&rsaquo;</span>
                    </p>
                    <p v-else class="text-center text-sm cursor-pointer font-light" @click="goBack">
                        <span class="text-xl">&lsaquo;</span> <span>Atrás</span> 
                    </p>
                </transition>
            </div>

            <div class="flex-grow-0">
                <!-- CIUDADES MOBILE -->
                <transition>
                    <div class="md:hidden flex flex-col justify-center">
                        <div v-if="!inCity" id="cities" class="text-customBlack flex flex-col text-center md:text-justify w-full">
                            <router-link class="font-light text-2xl mb-2 cursor-pointer city hover:citySelected cityMain" to="/type-delivery" @click.prevent="onCity = !onCity">San Pedro Sula</router-link>
                            <!-- <a class="font-light text-2xl mb-2 cursor-pointer city hover:citySelected" href="#" @click.prevent="onCity = !onCity">San Pedro Sula</a> -->
                            <a class="font-light text-2xl mb-2 cursor-pointer city hover:citySelected"
                            href="https://tegucigalpa.powerchicken.hn/">Tegucigalpa</a>
                            <a class="font-light text-2xl mb-2 cursor-pointer city hover:citySelected"
                            href="https://villanueva.powerchicken.hn/">Villanueva</a>
                            <a class="font-light text-2xl mb-2 cursor-pointer city hover:citySelected"
                            href="https://progreso.powerchicken.hn/">El Progreso</a>
                            <!-- @click="showModal('progreso')"> El Progreso</a> -->
                        </div>
                        <div v-else class="text-customBlack flex flex-col text-center md:text-justify">
                            <a class="font-light text-2xl mb-2 cursor-pointer city hover:branchOfficeSelected"
                            href="https://palenque.powerchicken.hn/">Palenque</a>
                            <!-- @click="showModal('palenque')">Palenque</a> -->
                            <a lang="es" class="font-light text-2xl mb-2 cursor-pointer city hover:branchOfficeSelected"
                            href="https://citymall.powerchicken.hn/">City Mall</a>
                            <!-- @click="showModal('citymall')">City Mall</a> -->
                            <a class="font-light text-2xl mb-2 cursor-pointer city hover:branchOfficeSelected" 
                            href="https://lalima.powerchicken.hn/">Salida a la Lima</a>
                            <!-- @click="showModal('lalima')">La Lima</a> -->
                        </div>
                    </div>
                </transition>
                <!-- CIUDADES DESKTOP -->
                <div class="hidden md:flex flex-row justify-between">
                    <div id="Citiess"  class="cities text-customBlack flex flex-col text-center md:text-left w-1/2">
                        <router-link class="cfont-light text-left sm:ctext-2xl lg:ctext-4xl cmb-2 cursor-pointer city flex justify-between items-baseline cityMain" to="/type-delivery"><span>San Pedro Sula</span>  
                            <span class="chevron-right hidden"></span>     
                        </router-link>
                        <!-- <router-link class="cfont-light text-left sm:ctext-2xl lg:ctext-4xl cmb-2 cursor-pointer city flex justify-between items-baseline cityMain" to="/type-delivery" @mouseover="showBranchOffice"><span>San Pedro Sula</span>  
                            <span class="chevron-right hidden"></span>     
                        </router-link> -->
                        <a class="empty cfont-light text-left sm:ctext-2xl lg:ctext-4xl cmb-2 cursor-pointer city"
                        href="https://tegucigalpa.powerchicken.hn/">Tegucigalpa</a>
                        <!-- @click="showModal('tegucigalpa')">Tegucigalpa</a> -->
                        <a class="empty cfont-light text-left sm:ctext-2xl lg:ctext-4xl cmb-2 cursor-pointer city"
                        href="https://villanueva.powerchicken.hn/">Villanueva</a>
                        <!-- @click="showModal('villanueva')"> Villanueva</a> -->
                        <a class="empty cfont-light text-left sm:ctext-2xl lg:ctext-4xl cmb-2 cursor-pointer city"
                        href="https://progreso.powerchicken.hn/">El Progreso</a>
                        <!-- @click="showModal('progreso')"> El Progreso</a> -->
                    </div>
                    <transition>
                        <div id="sps-branchOfficeList" class="text-customBlack flex flex-col w-1/2 hidden tabcontent">
                            <a class="cfont-light text-right sm:ctext-2xl lg:ctext-4xl cmb-2 cursor-pointer city hover:branchOfficeSelected"
                            href="https://palenque.powerchicken.hn/">Palenque</a>
                            <!-- @click="showModal('palenque')">Palenque</a> -->
                            <a lang="es" class="cfont-light text-right sm:ctext-2xl lg:ctext-4xl cmb-2 cursor-pointer city hover:branchOfficeSelected"
                            href="https://citymall.powerchicken.hn/">City Mall</a>
                            <!-- @click="showModal('citymall')">City Mall</a> -->
                            <a class="cfont-light text-right sm:ctext-2xl lg:ctext-4xl cmb-2 cursor-pointer city hover:branchOfficeSelected"
                            href="https://lalima.powerchicken.hn/">La Lima</a>
                            <!-- @click="showModal('lalima')">La Lima</a> -->
                        </div>
                    </transition>
                </div>
            </div>
            <!-- FOOTER -->
            <div class="flex justify-center md:justify-between pb-20">
                <div class="hidden md:block">
                    <!-- <img class=" ch-8" src="../assets/images/cards-logos.svg" alt="cards to pay" /> -->
                    <img class="logo-SECURE" src="../assets/images/logo-SECURE.svg" alt="cards to pay" />
                </div>
                <div class="block text-center">
                    <!-- <img class="img2" src="./../../assets/images/tarjetas.svg" /> -->
                    <!-- <img src="@/assets/images/powered-by-vendu-fac.svg" class="sm:hidden logo-vendu-movil"  /> -->
                    <!-- <img src="@/assets/images/logo-VENDU.svg" class="hidden md:block logo-vendu" /> -->
                    <img src="@/assets/images/logo-VENDU.svg" class="logo-vendu" />
                    <!-- w-132 h-22 -->
                </div>
                <!--<div class="flex font-display items-center justify-center text-sm ">
                    <div class="pw">© Power Chicken, {{ new Date().getFullYear() }}</div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import 'animate.css';
import modalOptions from './ModalOptions';
export default {
    name: "hero",
    components: {
        modalOptions
    },
    data() {
        return {
            onCity: false,
            dataToModal:{urlNormal: '', urlPreventa: ''},
            isShowModal: false,
        }
    },
    computed:{
        inCity () {
            if(this.onCity){
                this.animateCities();
                return true;
            }else{
                this.animateCities();
                return false;
            }
        }
    },
    created(){
    },
    mounted(){
        this.animateCities();
        this.showBranchOffice();
    },
    methods:{
        animateCities(){
            const cities = document.querySelectorAll('.cities a');
            const cityMd = document.querySelectorAll('#cities a');
            
            cities.forEach((link, index)=>{// animate on desktop
                setTimeout(()=>{
                    link.classList.toggle('animate__animated');
                    link.classList.toggle('animate__fadeInDown');
                    //link.classList.toggle('animate__delay-2s');
                },300*index);
            });

            cityMd.forEach((link, index)=>{// animate on movil
                setTimeout(()=>{
                    link.classList.toggle('animate__animated');
                    link.classList.toggle('animate__fadeInDown');
                    //link.classList.toggle('animate__delay-2s');
                },300*index);
            });
            
        },
        goBack(){
            this.onCity = !this.onCity;
            this.animateCities();
        },
        showBranchOffice(){
            const branchOfficeList = document.querySelector('#sps-branchOfficeList');
            const spsbranchOfficeList = document.querySelectorAll('#sps-branchOfficeList a');
            const  branchOffice = document.querySelector('.cityMain');
            const  branchOfficeEmpty = document.querySelector('.empty');
            const chevron = document.querySelector('.chevron-right');

            branchOffice.addEventListener('click', function (){
                chevron.classList.remove('hidden');
                branchOfficeList.classList.remove('hidden');
                branchOfficeList.classList.add('block');
        
                spsbranchOfficeList.forEach((link, index)=>{// animate branchOffice on desktop
                    setTimeout(()=>{
                        link.classList.toggle('animate__animated');
                        link.classList.toggle('animate__fadeInDown');
                        //link.classList.toggle('animate__delay-2s');
                    },300*index);
                });
            });

            branchOfficeEmpty.addEventListener('click', function (){
                branchOfficeList.classList.remove('block');
                branchOfficeList.classList.add('hidden');
                chevron.classList.remove('hidden');
                chevron.classList.add('hidden');
            });
        },
        showModal(sucursal){
            if(sucursal === 'palenque'){
                this.dataToModal = { urlNormal: 'https://palenque.powerchicken.hn/', urlPreventa: 'https://prepalenque.powerchicken.hn/'}
            }
            if(sucursal === 'citymall'){
                this.dataToModal = { urlNormal: "https://citymall.powerchicken.hn/", urlPreventa: "https://precitymall.powerchicken.hn/"}
            }
            if(sucursal === 'lalima'){
                this.dataToModal = { urlNormal: "https://lalima.powerchicken.hn/", urlPreventa: "https://prelalima.powerchicken.hn/"}
            }
            if(sucursal === 'villanueva'){
                this.dataToModal = { urlNormal: "https://villanueva.powerchicken.hn/", urlPreventa: "https://previllanueva.powerchicken.hn/"}
            }
            if(sucursal === 'progreso'){
                this.dataToModal = { urlNormal: "https://progreso.powerchicken.hn/", urlPreventa: "https://preprogreso.powerchicken.hn/"}
            }
            if(sucursal === 'tegucigalpa'){
                this.dataToModal = { urlNormal: 'https://tegucigalpa.powerchicken.hn/', urlPreventa: 'https://pretegucigalpa.powerchicken.hn/'}
            }                   
            this.isShowModal = true; 
            console.log('Hola');
        }
    }
};
</script>

<style lang="scss">
$mainFont : 'Montserrat', sans-serif;

body, p{
    font-family: $mainFont;
}

.city {
    color: rgb(63, 62, 62);
    font-family: $mainFont;
}

/*
 Transitions
*/
.v-enter-active, .v-leave-active {
    transition: opacity .5s;
}

.v-enter, .v-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.cities a:active,
.cities a:focus{
    border-color:transparent;
}

.cities:hover a:hover{
    color: #DA2D2A;
    opacity: 1;
}

.cities:hover a.active{
    color: #DA2D2A;
    opacity: 1;
}

.cities:hover a{
  color: rgba(0,0,0,.2);
}

.chevron-right{
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid #444950;
    transform: rotate(45deg);
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    border-radius: 2px;
    margin-right: -1.3rem;
}

.logo-vendu{
    width: 8.125rem;
    height: 2.125rem;
    @media screen and (min-width:768px){
        width: 10.25rem;
        height: 2.7rem;
    }
}
.logo-SECURE{
    width: 18.25rem;
    height: 2rem;
}

.logo-vendu-movil{
    width: 100%;
    height: 60px;
}

</style>
