<template>
  <Hero />
  <!-- <Soporte /> -->
</template>

<script>
import Hero from "../components/Hero.vue";
//import Soporte from "../components/Soporte.vue";

export default {
  name: "Home",
  components: {
    Hero,
    //Soporte
  }
};
</script>
